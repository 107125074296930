import React from 'react';
import { Image, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Card, Box, Title } from '../../components';
import { LoginForm } from '../../components/Login';
import PatternBg from '../../assets/img/pattern.jpeg';
import Logo from '../../assets/img/logo_color.png';
import { useAuth } from '../../context';

const { Paragraph } = Typography;

const LoginPage: React.FC = () => {
  const { authenticated, loading } = useAuth();
  const { t } = useTranslation();

  if ((!loading && authenticated) || (loading && !authenticated)) {
    return <Navigate replace to="/" />;
  }

  return (
    <Box
      backgroundImage={`url('${PatternBg}')`}
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems={['flex-start', 'center']}
    >
      <Title title={t('login.pageTitle')} />
      <Card
        elevation={8}
        width={['100%', 600, 500]}
        p={[0, 24]}
        minHeight={['100%', 'auto']}
      >
        <Box display="flex" justifyContent="center" mb={3}>
          <Image preview={false} width="100%" src={Logo} />
        </Box>
        <Paragraph style={{ textAlign: 'center', marginBottom: 16 }}>
          {t('common.slogan')}
        </Paragraph>
        <LoginForm />
      </Card>
    </Box>
  );
};

export default LoginPage;
