import client from '../config/client';
import { Address, Contact } from '../models';
import Service from './service';

export default class AddressService implements Service {
  private static instance = client;

  public static async getByUserId(userId: number): Promise<Address[]> {
    const contacts: Contact[] = await this.instance
      .service('user_shipping_contacts')
      .find({ query: { userId } });
    return contacts.map(contact => contact.address);
  }
}
