import React from 'react';
import { Layout, Button } from 'antd';
import { FacebookFilled, InstagramFilled } from '@ant-design/icons';
import {
  space,
  SpaceProps,
  compose,
  position,
  PositionProps,
  layout,
  LayoutProps,
  color,
  ColorProps,
} from 'styled-system';
import styled from 'styled-components';
import { Box } from '..';
import { useTranslation } from 'react-i18next';

type FooterProps = SpaceProps &
  PositionProps &
  LayoutProps &
  ColorProps &
  typeof Layout.Footer;

const { Footer: FooterBase } = Layout;
const capabilities = compose(space, position, layout, color);

const StyledFooter = styled(FooterBase)<FooterProps>`
  ${capabilities}
`;

const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledFooter backgroundColor="#001978" padding={2}>
      <Box width="100%" display="flex" flexDirection="row">
        <Box flex={5 / 8}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-around"
          >
            <Button style={{ color: 'white', fontSize: 12 }} type="text">
              {t('layout.footer.version')}
            </Button>
            <Button style={{ color: 'white', fontSize: 12 }} type="text">
              {t('layout.footer.faqs')}
            </Button>
            <Button style={{ color: 'white', fontSize: 12 }} type="text">
              {t('layout.footer.contact')}
            </Button>
            <Button style={{ color: 'white', fontSize: 12 }} type="text">
              {t('layout.footer.language')}
            </Button>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-around"
          >
            <Button style={{ color: 'white', fontSize: 12 }} type="text">
              {t('layout.footer.termsAndConditions')}
            </Button>
          </Box>
        </Box>
        <Box flex={3 / 8} display="flex" justifyContent="space-between">
          <Button
            type="text"
            shape="circle"
            icon={<FacebookFilled style={{ fontSize: 20, color: 'white' }} />}
            size="small"
          />
          <Button
            type="text"
            shape="circle"
            icon={<InstagramFilled style={{ fontSize: 20, color: 'white' }} />}
            size="small"
          />
        </Box>
      </Box>
    </StyledFooter>
  );
};

export default Footer;
