import { PayloadAction } from '@reduxjs/toolkit';
import { put, select, takeLatest } from 'redux-saga/effects';
import { searchActions, selectSearchCategory, selectSearchQuery } from '.';
import { Category } from '../../models';
import { selectCurrentCategory } from '../categories';
import { productsActions } from '../products';

function* handleSearchInput(action: PayloadAction<string>) {
  const search = action.payload;
  if (!search) {
    const currentCategory: Category = yield select(selectCurrentCategory);
    yield put(
      productsActions.fetchProducts({
        category: currentCategory.id,
      }),
    );
    return;
  }
  const category: number = yield select(selectSearchCategory);
  yield put(
    productsActions.fetchProducts({
      category,
      search,
    }),
  );
}

function* handleSearchCategory(action: PayloadAction<number>) {
  const category = action.payload;
  const search: string = yield select(selectSearchQuery);
  if (search) {
    yield put(
      productsActions.fetchProducts({
        category,
        search,
      }),
    );
    return;
  }
  const currentCategory: Category = yield select(selectCurrentCategory);
  yield put(productsActions.fetchProducts({ category: currentCategory.id }));
}

export default function* searchSaga() {
  yield takeLatest(searchActions.setSearch.type, handleSearchInput);
  yield takeLatest(searchActions.setSearchCategory.type, handleSearchCategory);
}
