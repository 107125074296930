import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Address, User } from '../../models';
import { UserState } from './user.types';

const initialState: UserState = {
  addresses: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAddresses(state, action: PayloadAction<Address[]>) {
      state.addresses = action.payload;
    },
    setAddress(state, action: PayloadAction<Address>) {
      if (state.addresses?.length) {
        state.addresses.push(action.payload);
        return;
      }
      state.addresses = [action.payload];
    },
    setDesiredAddress(state, action: PayloadAction<Address>) {
      state.desiredAddres = action.payload;
    },
    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    setAuthenticationToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
  },
});

export const { reducer: userReducer, actions: userActions } = userSlice;
