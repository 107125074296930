import { Address } from '../models';

export const currency = (amount: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);
};

export const capitalize = (str: string) => {
  return `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`;
};

export const getAddress = (
  address: Address,
) => `${address.street} #${address.extNum}, ${address.suburb.name},
${address.postal_code.city.name},
${address.postal_code.city.state.name},
${address.postal_code.city.state.country.name},
${address.postal_code.code}`;
