import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '..';

const searchSlice = (state: AppState) => state.search;

export const selectSearchQuery = createSelector(
  searchSlice,
  state => state.search,
);

export const selectSearchCategory = createSelector(
  searchSlice,
  state => state.category,
);
