import { Paginated } from '@feathersjs/feathers';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, debounce, put, select, takeLatest } from 'redux-saga/effects';
import {
  productsActions as actions,
  productsActions,
  selectSelectedProductAttributes,
} from '.';
import { Product, Warehouse } from '../../models';
import { ProductService } from '../../services';
import { selectWarehouse } from '../warehouse';
import { FetchProductsOptions } from './products.types';

function* fetchProducts(action: PayloadAction<FetchProductsOptions>) {
  const options = action.payload;
  const query: Record<string, any> = {};
  const selectedWarehouse: Warehouse = yield select(selectWarehouse);
  const attributes: number[][] = yield select(selectSelectedProductAttributes);
  if (options?.category) {
    query['categories_ids'] = `[${options.category}]`;
  }
  if (options?.search) {
    query['search'] = options.search;
  }
  if (selectedWarehouse) {
    query['warehouse_id'] = selectedWarehouse.id;
  }
  if (attributes) {
    query['attributes_ids'] = JSON.stringify(attributes);
  }
  const result: Paginated<Product> = yield call(
    [ProductService, ProductService.getAll],
    {
      query,
    },
  );
  yield put(productsActions.setProducts(result.data));
}

export default function* productsSaga() {
  yield takeLatest(actions.fetchProducts.type, fetchProducts);
  yield debounce(1000, actions.setSelectedAttributes.type, fetchProducts);
}
