import client from '../config/client';
import { Category } from '../models';
import Service from './service';

export default class CategoryService implements Service {
  private static instance = client;

  static async getAll(): Promise<Category[]> {
    return await this.instance.service('custom_categories').find();
  }
}
