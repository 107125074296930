import { FilterOutlined, SortAscendingOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import React from 'react';

interface Props {
  onFilter(): void;
  onSort(): void;
}

const FilterHandler: React.FC<Props> = ({ onFilter, onSort }) => {
  return (
    <Space>
      <Button
        type="primary"
        icon={<FilterOutlined />}
        size="small"
        onClick={onFilter}
      />
      <Button
        type="primary"
        icon={<SortAscendingOutlined />}
        size="small"
        onClick={onSort}
      />
    </Space>
  );
};

export default FilterHandler;
