import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { userActions } from '.';
import { Address, Warehouse } from '../../models';
import { WarehouseService } from '../../services';
import { warehouseActions } from '../warehouse';

export function* fetchWarehouse(action: PayloadAction<Address>) {
  const address = action.payload;
  const warehouse: Warehouse[] = yield call(
    [WarehouseService, WarehouseService.getByZipCode],
    address.postal_code.code,
  );
  yield put(warehouseActions.setCurrentWarehouse(warehouse[0]));
}

export default function* userSaga() {
  yield takeLatest(userActions.setDesiredAddress.type, fetchWarehouse);
}
