import React from 'react';
import { Box, Category } from '..';
import { Category as CategoryModel } from '../../models';

interface Props {
  data: CategoryModel[];
  onClick(category: CategoryModel): void;
  selected?: CategoryModel;
}

const CategoriesCarousel: React.FC<Props> = ({ data, onClick, selected }) => {
  return (
    <Box width="100%" display="flex" overflowX="auto" mb={4}>
      {data &&
        data.map(category => {
          const selectCategory = () => onClick(category);
          return (
            <Category
              key={`category-${category.id}`}
              selected={selected?.id === category.id}
              category={category}
              onClick={selectCategory}
            />
          );
        })}
    </Box>
  );
};

export default CategoriesCarousel;
