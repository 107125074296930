import client from '../config/client';
import { Warehouse } from '../models';
import Service from './service';

export default class WarehouseService implements Service {
  private static instance = client;

  public static async getByZipCode(zipCode: string): Promise<Warehouse[]> {
    return await this.instance
      .service('warehouses_postal_code')
      .find({ query: { postal_code: zipCode } });
  }
}
