export interface FontSizes {
  [index: number]: number;
  small: number;
  normal: number;
  text: number;
  large: number;
  h5: number;
  h4: number;
  h3: number;
  h2: number;
  h1: number;
}

const fontSizes = [12, 14, 16, 20, 24, 30, 38];

(fontSizes as unknown as FontSizes).small = fontSizes[0];
(fontSizes as unknown as FontSizes).normal = fontSizes[1];
(fontSizes as unknown as FontSizes).text = fontSizes[1];
(fontSizes as unknown as FontSizes).large = fontSizes[3];
(fontSizes as unknown as FontSizes).h5 = fontSizes[2];
(fontSizes as unknown as FontSizes).h4 = fontSizes[3];
(fontSizes as unknown as FontSizes).h3 = fontSizes[4];
(fontSizes as unknown as FontSizes).h2 = fontSizes[5];
(fontSizes as unknown as FontSizes).h1 = fontSizes[6];

export default fontSizes as unknown as FontSizes;
