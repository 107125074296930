import React from 'react';
import { Helmet } from 'react-helmet';

export interface Props {
  title: string;
}

const Title: React.FC<Props> = ({ title }) => {
  return (
    <Helmet titleTemplate="Zellship - %s">
      <title>{title}</title>
    </Helmet>
  );
};

export default Title;
