import { Drawer, Collapse, Checkbox } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Text } from '..';
import { useLang } from '../../context/Lang';
import { Attribute, AttributeOption } from '../../models';
import { useDispatch } from '../../store';
import { productsActions, selectProductAttributes } from '../../store/products';
import { capitalize } from '../../utils';

const { Panel } = Collapse;

interface Props {
  isVisible: boolean;
  onClose(): void;
}

const FilterDrawer: React.FC<Props> = ({ isVisible, onClose }) => {
  const { t } = useTranslation();
  const { lang } = useLang();
  const dispatch = useDispatch();
  const attributes = useSelector(selectProductAttributes);
  const [selectedAttributes, setSelectedAttributes] = useState<
    Record<number, any[]>
  >({});

  const attrMapper = (attr: Attribute) => `attr-${attr.id}`;

  const langLabelKeyGetter = (attr: Attribute | AttributeOption): string =>
    (attr as any)[`label${capitalize(lang)}`];

  const handleOptionsChange = (
    values: CheckboxValueType[],
    attrId: Attribute['id'],
  ) => {
    const incommingAttributes = { [attrId]: values };
    const currentAttributes = Object.assign(
      selectedAttributes,
      incommingAttributes,
    );
    setSelectedAttributes(currentAttributes);
    const selectedAttributesArr = Object.keys(currentAttributes).flatMap(k => {
      return currentAttributes[Number(k)].map(opt => [Number(k), opt]);
    });
    dispatch(productsActions.setSelectedAttributes(selectedAttributesArr));
  };

  const handleAttributeChange = (attrId: Attribute['id']) => {
    return (values: CheckboxValueType[]) => handleOptionsChange(values, attrId);
  };

  return attributes && attributes.length > 0 ? (
    <Drawer placement="right" closable visible={isVisible} onClose={onClose}>
      <Text
        textAlign="center"
        color="blue.500"
        fontWeight="bold"
        fontSize="large"
        width="100%"
        my={4}
        margin={0}
      >
        {t('layout.filters.title')}
      </Text>
      <Collapse
        defaultActiveKey={attributes.map(attr => attrMapper(attr))}
        bordered={false}
        expandIconPosition="right"
        ghost
      >
        {attributes.map(attr => (
          <Panel key={attrMapper(attr)} header={langLabelKeyGetter(attr)}>
            <Checkbox.Group
              options={attr.attribute_options.map(opt => ({
                label: langLabelKeyGetter(opt),
                value: opt.id,
              }))}
              onChange={handleAttributeChange(attr.id)}
              style={{ display: 'flex', flexDirection: 'column' }}
            />
          </Panel>
        ))}
      </Collapse>
    </Drawer>
  ) : null;
};

export default FilterDrawer;
