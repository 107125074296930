import { PushpinOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { Box, Text } from '..';
import { selectReadableAddress } from '../../store/user';

interface Props {
  onClick(): void;
}

const DeliveryLocation: React.FC<Props> = ({ onClick }) => {
  const theme = useTheme();
  const address = useSelector(selectReadableAddress);

  return (
    <Box
      width={130}
      height={30}
      display="flex"
      flexDirection="row"
      alignItems="center"
      onClick={onClick}
    >
      <PushpinOutlined
        style={{
          fontSize: theme.fontSizes.normal,
          color: theme.colors.white,
          marginRight: theme.space.get(1),
        }}
      />
      <Text
        width="100%"
        as="text"
        color="white"
        fontSize="small"
        ellipsis={{ row: 2 }}
        lineHeight={1}
      >
        {t('layout.location.sendTo', { address })}
      </Text>
    </Box>
  );
};

export default DeliveryLocation;
