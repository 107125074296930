import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Layout } from './components';
import { AddressService, CategoryService, ProductService } from './services';
import { useDispatch } from './store';
import { categoriesActions } from './store/categories';
import { productsActions } from './store/products';
import { selectCurrentUser, userActions } from './store/user';

const Main: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);

  const loadData = useCallback(async () => {
    const attributes = await ProductService.getAttributes();
    console.log(attributes);
    dispatch(productsActions.setProductAttributes(attributes));
    const categoriesResponse = await CategoryService.getAll();
    dispatch(categoriesActions.setCategories(categoriesResponse));
    if (categoriesResponse?.length > 0) {
      const currentCategory = categoriesResponse[0];
      dispatch(categoriesActions.setCurrentCategory(currentCategory));
      const productsResponse = await ProductService.getByCategory(
        currentCategory.id,
      );
      dispatch(productsActions.setProducts(productsResponse.data));
    }
    const addresses = await AddressService.getByUserId(user!.id);
    dispatch(userActions.setAddresses(addresses));
    // Default select first address?
    dispatch(userActions.setDesiredAddress(addresses[0]));
  }, [dispatch, user]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return <Layout />;
};

export default Main;
