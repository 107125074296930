import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import { AuthProvider } from './context/Auth';
import { LangProvider } from './context/Lang';
import AppRouter from './AppRouter';
import { store, persistor } from './store';
import { theme } from './theme';

function App() {
  return (
    <Suspense fallback="Loading...">
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <AuthProvider>
              <LangProvider>
                <AppRouter />
              </LangProvider>
            </AuthProvider>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
