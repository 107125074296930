import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Warehouse } from '../../models';
import { WarehouseState } from './warehouse.types';

const initialState: WarehouseState = {};

const warehouseSlice = createSlice({
  name: 'warehouse',
  initialState,
  reducers: {
    setCurrentWarehouse(state, action: PayloadAction<Warehouse>) {
      state.warehouse = action.payload;
    },
  },
});

export const { reducer: warehouseReducer, actions: warehouseActions } =
  warehouseSlice;
