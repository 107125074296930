import { useEffect, useState } from 'react';

interface UseDialogHandlerHook {
  afterOpen?(): void;
  afterClose?(): void;
}

export type UseDialogHandlerHookProps = UseDialogHandlerHook | undefined;

export default function useDialogHandler(
  dialogProps?: UseDialogHandlerHookProps,
) {
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const open = () => {
    setIsVisible(true);
    if (!isTouched) {
      setIsTouched(true);
    }
  };
  const close = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    if (isTouched && isVisible && dialogProps?.afterOpen) {
      dialogProps?.afterOpen();
    }
    if (isTouched && !isVisible && dialogProps?.afterClose) {
      dialogProps?.afterClose();
    }
  }, [isVisible, isTouched, dialogProps]);

  return { isVisible, close, open };
}
