import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '..';

const productsSlice = (state: AppState) => state.products;

export const selectProducts = createSelector(
  productsSlice,
  state => state.products,
);

export const selectHighlightedProducts = createSelector(productsSlice, state =>
  state.products.filter(product => product.keywords?.includes('highlight')),
);

export const selectProductAttributes = createSelector(
  productsSlice,
  state => state.attributes,
);

export const selectSelectedProductAttributes = createSelector(
  productsSlice,
  state => state.selectedAttributes,
);
