import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import createSagaMiddleware from 'redux-saga';
import { useDispatch as useBaseDispatch } from 'react-redux';
import rootReducer from './reducer';
import rootSaga from './saga';

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => [
    ...getDefaultMiddleware(),
    sagaMiddleware,
  ],
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export const useDispatch = () => useBaseDispatch<AppDispatch>();

if (module.hot) {
  module.hot.accept('./reducer', () => {
    // This fetch the new state of the above reducers.
    const nextRootReducer = require('./reducer').default;
    store.replaceReducer(persistReducer(persistConfig, nextRootReducer));
  });
}

export default store;
