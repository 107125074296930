import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import {
  compose,
  color,
  typography,
  space,
  layout,
  ColorProps,
  TypographyProps,
  SpaceProps,
  LayoutProps,
} from 'styled-system';

const { Text: BaseText, Paragraph } = Typography;

type StyledTextProps = TypographyProps & ColorProps & SpaceProps & LayoutProps;

const capabilities = compose(color, typography, space, layout);

const StyledText = styled(BaseText)<StyledTextProps>`
  ${capabilities}
`;

const StyledParagraph = styled(Paragraph)<StyledTextProps>`
  &.ant-typography {
    ${capabilities}
  }
`;

interface TextProps extends ColorProps, TypographyProps, SpaceProps {
  as?: 'text' | 'paragraph';
  style?: React.CSSProperties;
  [key: string]: any;
}

const Text: React.FC<TextProps> = ({
  children,
  as = 'paragraph',
  ...props
}) => {
  if (as === 'paragraph') {
    return <StyledParagraph {...props}>{children}</StyledParagraph>;
  }
  return <StyledText {...props}>{children}</StyledText>;
};

export default Text;
