import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const STORAGE_KEY = 'preferredLang';

interface Lang {
  key: string;
  name: string;
  icon?: string;
}

interface LangContext {
  changeLang(lang: string): void;
  lang: string;
  availableLangs: Lang[];
}

const availableLangs: LangContext['availableLangs'] = [
  {
    key: 'en',
    name: 'English',
    icon: '🇺🇸',
  },
  {
    key: 'es',
    name: 'Español',
    icon: '🇲🇽',
  },
];

const langContext = React.createContext<LangContext>({} as LangContext);

function useLang(): LangContext {
  const currentLang = localStorage.getItem(STORAGE_KEY);
  const [lang, setLang] = useState<string>(currentLang || 'en');
  const { i18n } = useTranslation();

  const changeLang: LangContext['changeLang'] = incommingLang => {
    setLang(incommingLang);
    i18n.changeLanguage(incommingLang);
    localStorage.setItem(STORAGE_KEY, incommingLang);
  };

  return {
    changeLang,
    lang,
    availableLangs,
  };
}

export const LangProvider: React.FC = ({ children }) => {
  const lang = useLang();

  return <langContext.Provider value={lang}>{children}</langContext.Provider>;
};

export default function LangConsumer() {
  return React.useContext(langContext);
}
