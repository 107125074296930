import React, { useEffect, useState } from 'react';
import { Layout, Button, Image } from 'antd';
import { MenuOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import {
  space,
  SpaceProps,
  compose,
  position,
  PositionProps,
  layout,
  LayoutProps,
  color,
  ColorProps,
} from 'styled-system';
import styled from 'styled-components';
import { Box, DeliveryLocation, Text } from '..';
import Logo from '../../assets/img/logo.png';
import { theme } from '../../theme';
import { useBreakpoints } from '../../hooks';
import SearchBar from './SearchBar';
import { Link } from 'react-router-dom';
import { Banner } from '../../models';
import { BannerService } from '../../services';

interface HeaderProps {
  onMenuClick(): void;
  onAddressClick(): void;
}

type StyledHeaderProps = SpaceProps &
  PositionProps &
  LayoutProps &
  ColorProps &
  typeof Layout.Header;

const { Header: HeaderBase } = Layout;
const capabilities = compose(space, position, layout, color);

const StyledHeader = styled(HeaderBase)<StyledHeaderProps>`
  &.ant-layout-header {
    height: initial;
    min-height: 64px;
  }
  ${capabilities}
`;

const Header: React.FC<HeaderProps> = ({ onMenuClick, onAddressClick }) => {
  const { isBreakpointEqualsAndBelow, isBreakpointEqualsAndAbove } =
    useBreakpoints();
  const [banner, setBanner] = useState<Banner | null>(null);

  const getBanner = async () => {
    const result = await BannerService.getByLocation('mainPageBanner');
    if (result) {
      setBanner(result);
    }
  };

  useEffect(() => {
    getBanner();
  }, []);

  return (
    <>
      <StyledHeader bg="primary" paddingX={4}>
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          pt={[4, 4, 0]}
        >
          <Button
            type="text"
            icon={<MenuOutlined style={{ color: theme.colors.white }} />}
            size="small"
            onClick={onMenuClick}
          />
          {isBreakpointEqualsAndBelow('md') && (
            <Link style={{ display: 'inherit' }} to="/">
              <Image preview={false} width={120} src={Logo} />
            </Link>
          )}
          {isBreakpointEqualsAndAbove('lg') && (
            <Box flex={1} px={4}>
              <SearchBar />
            </Box>
          )}
          <Box display="flex" flexDirection="row" alignItems="center">
            <DeliveryLocation onClick={onAddressClick} />
            <Button
              type="text"
              icon={
                <ShoppingCartOutlined
                  style={{
                    fontSize: theme.fontSizes.large,
                    color: theme.colors.white,
                  }}
                />
              }
              size="small"
            />
          </Box>
        </Box>
        {isBreakpointEqualsAndBelow('md') && <SearchBar />}
      </StyledHeader>
      {banner && (
        <Box bg="blue.500" px={2} display="flex" alignItems="center">
          <Text
            as="text"
            pb={0}
            color="white"
            fontSize="small"
            style={{ width: '100%' }}
            ellipsis
          >
            {banner.value}
          </Text>
        </Box>
      )}
    </>
  );
};

export default Header;
