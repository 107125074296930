import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Attribute, Product } from '../../models';
import { FetchProductsOptions, ProductsState } from './products.types';

const initialState: ProductsState = {
  products: [],
  loading: false,
  attributes: [],
  selectedAttributes: [],
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProducts(state, action: PayloadAction<Product[]>) {
      state.products = action.payload;
    },
    fetchProducts(state, _: PayloadAction<FetchProductsOptions | undefined>) {
      state.loading = true;
    },
    setProductAttributes(state, action: PayloadAction<Attribute[]>) {
      state.attributes = action.payload;
    },
    setSelectedAttributes(state, action: PayloadAction<number[][]>) {
      state.selectedAttributes = action.payload;
    },
  },
});

export const { reducer: productsReducer, actions: productsActions } =
  productsSlice;
