const color = 'rgb(0 0 0 / 10%)';
const shadows = [
  `0px 0px 0px 0px ${color}`,
  `0px 1px 3px 0px ${color}`,
  `0px 1px 5px 0px ${color}`,
  `0px 1px 8px 0px ${color}`,
  `0px 1px 10px 0px ${color}`,
  `0px 1px 14px 0px ${color}`,
  `0px 1px 18px 0px ${color}`,
  `0px 2px 16px 1px ${color}`,
  `0px 3px 14px 2px ${color}`,
  `0px 3px 16px 2px ${color}`,
  `0px 4px 18px 3px ${color}`,
  `0px 4px 20px 3px ${color}`,
  `0px 5px 22px 4px ${color}`,
  `0px 5px 24px 4px ${color}`,
  `0px 5px 26px 4px ${color}`,
  `0px 6px 28px 5px ${color}`,
  `0px 6px 30px 5px ${color}`,
  `0px 6px 32px 5px ${color}`,
  `0px 7px 34px 6px ${color}`,
  `0px 7px 36px 6px ${color}`,
  `0px 8px 38px 7px ${color}`,
  `0px 8px 40px 7px ${color}`,
  `0px 8px 42px 7px ${color}`,
  `0px 9px 44px 8px ${color}`,
  `0px 9px 46px 8px ${color}`,
];

export default shadows;
