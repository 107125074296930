import React from 'react';
import { Form, Input, Button, Divider, Typography } from 'antd';
import { GoogleOutlined, FacebookOutlined } from '@ant-design/icons';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import { Box } from '../../components';
import { emailPattern, passwordPattern } from '../../utils';
import { useAuth } from '../../context';
import { useBreakpoints } from '../../hooks';

const { Paragraph } = Typography;

const RegisterForm: React.FC = () => {
  const { authError, register } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isBreakpointEqualsAndBelow } = useBreakpoints();

  const onFinish = async (values: any) => {
    await register({
      strategy: 'local',
      email: values.email,
      password: values.password,
    });
    navigate('/', { replace: true });
  };

  const goToTNC = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.open(process.env.REACT_APP_TNC_URL, '_blank');
  };

  const goToPrivacy = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.open(process.env.REACT_APP_PRIVACY_URL, '_blank');
  };

  return (
    <Box>
      <Button
        style={{ marginBottom: 12 }}
        shape="round"
        type="primary"
        block
        size={isBreakpointEqualsAndBelow('sm') ? 'middle' : 'large'}
        icon={<GoogleOutlined />}
      >
        {t('login.google')}
      </Button>
      <Button
        shape="round"
        type="primary"
        block
        size={isBreakpointEqualsAndBelow('sm') ? 'middle' : 'large'}
        icon={<FacebookOutlined />}
      >
        {t('login.facebook')}
      </Button>
      <Divider plain orientation="center">
        {t('common.or')}
      </Divider>
      <Form name="login" initialValues={{ remember: true }} onFinish={onFinish}>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: t('register.errors.email.required') },
            {
              pattern: emailPattern,
              message: t('register.errors.email.invalid'),
            },
          ]}
        >
          <Input
            style={{ borderRadius: 40 }}
            size={isBreakpointEqualsAndBelow('sm') ? 'middle' : 'large'}
            placeholder={t('register.inputs.email.placeholder')}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: t('register.errors.password.required') },
            {
              pattern: passwordPattern,
              message: t('register.errors.password.invalid'),
            },
          ]}
        >
          <Input
            style={{ borderRadius: 40 }}
            size={isBreakpointEqualsAndBelow('sm') ? 'middle' : 'large'}
            type="password"
            placeholder={t('register.inputs.password.placeholder')}
          />
        </Form.Item>
        <Form.Item
          name="passwordConfirm"
          rules={[
            { required: true, message: t('register.errors.confirm.required') },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(t('register.errors.confirm.invalid')),
                );
              },
            }),
          ]}
        >
          <Input
            style={{ borderRadius: 40 }}
            size={isBreakpointEqualsAndBelow('sm') ? 'middle' : 'large'}
            type="password"
            placeholder={t('register.inputs.confirm.placeholder')}
          />
        </Form.Item>
        {authError && (
          <Paragraph
            type="danger"
            style={{ marginBottom: 16, textAlign: 'center' }}
          >
            {authError}
          </Paragraph>
        )}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="signup-form-button"
            shape="round"
            size={isBreakpointEqualsAndBelow('sm') ? 'middle' : 'large'}
            block
          >
            {t('register.elements.mainButtonText')}
          </Button>
        </Form.Item>
        <Form.Item
          style={{
            textAlign: 'center',
            marginBottom: 12,
            fontSize: 12,
            maxWidth: '80%',
            margin: '0 auto',
          }}
        >
          <Trans i18nKey="register.elements.acceptTermsAndPrivacy">
            By creating an account, you are agreeing to our
            <Link onClick={goToTNC} to="">
              Terms of Service
            </Link>
            and
            <Link onClick={goToPrivacy} to="">
              Privacy Policy
            </Link>
            .
          </Trans>
        </Form.Item>
        <Form.Item style={{ textAlign: 'center', marginBottom: 0 }}>
          <Trans i18nKey="register.elements.login">
            Already have an account? <Link to="/login">Sign in now!</Link>
          </Trans>
        </Form.Item>
      </Form>
    </Box>
  );
};

export default RegisterForm;
