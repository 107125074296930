import client from '../config/client';
import { Paginated, Params } from '@feathersjs/feathers';
import { Attribute, Product } from '../models';
import Service from './service';

export default class ProductService implements Service {
  private static instance = client;

  public static async getAll(params?: Params): Promise<Paginated<Product>> {
    return await this.instance.service('customer_products').find(params);
  }

  public static async getByCategory(
    categoryId: number,
  ): Promise<Paginated<Product>> {
    if (!categoryId) {
      throw new Error('No category id provided');
    }
    return await this.instance.service('customer_products').find({
      query: { categories_ids: `[${categoryId}]` },
    });
  }

  public static async getAttributes(): Promise<Attribute[]> {
    return await this.instance.service('attributes').find();
  }
}
