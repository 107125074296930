import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SearchState } from './search.types';

const initialState: SearchState = {
  search: '',
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    setSearchCategory(state, action: PayloadAction<number>) {
      state.category = action.payload;
    },
  },
});

export const { actions: searchActions, reducer: searchReducer } = searchSlice;
