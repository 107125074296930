import shadows from './shadows';
import space from './space';
import fontSizes from './fontSizes';
import colors from './colors';
import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  shadows,
  space,
  fontSizes,
  colors,
};

export default theme;
