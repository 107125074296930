import {
  space,
  color,
  layout,
  flexbox,
  background,
  border,
  position,
  shadow,
  compose,
  SpaceProps,
  ColorProps,
  LayoutProps,
  FlexboxProps,
  BackgroundProps,
  BorderProps,
  PositionProps,
  ShadowProps,
} from 'styled-system';
import styled from 'styled-components';

export type BoxProps = SpaceProps &
  ColorProps &
  LayoutProps &
  FlexboxProps &
  BackgroundProps &
  BorderProps &
  PositionProps &
  ShadowProps;

const capabilities = compose(
  space,
  color,
  layout,
  flexbox,
  background,
  border,
  position,
  shadow,
);

const Box = styled.div<BoxProps>`
  ${capabilities}
`;

export default Box;
