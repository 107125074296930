import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '..';

const categoriesSlice = (state: AppState) => state.categories;

export const selectCategories = createSelector(
  categoriesSlice,
  state => state.categories,
);

export const selectCurrentCategory = createSelector(
  categoriesSlice,
  state => state.currentCategory,
);
