import { combineReducers } from 'redux';
import { categoriesReducer } from './categories';
import { productsReducer } from './products';
import { searchReducer } from './search';
import { userReducer } from './user';
import { warehouseReducer } from './warehouse/warehouse.slice';

const rootReducer = combineReducers({
  categories: categoriesReducer,
  products: productsReducer,
  user: userReducer,
  search: searchReducer,
  warehouse: warehouseReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
