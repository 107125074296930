import React from 'react';
import { Form, Input, Button, Checkbox, Divider, Typography } from 'antd';
import { GoogleOutlined, FacebookOutlined } from '@ant-design/icons';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import { Box } from '../../components';
import { emailPattern } from '../../utils';
import { useAuth } from '../../context';
import { useBreakpoints } from '../../hooks';

const { Paragraph } = Typography;

interface LoginInput {
  email: string;
  password: string;
  remember: boolean;
}

const LoginForm: React.FC = () => {
  const { login, authError, cleanupErrors } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isBreakpointEqualsAndBelow } = useBreakpoints();

  const onFinish = async (values: LoginInput) => {
    await login({
      strategy: 'local',
      ...values,
    });
    navigate('/', { replace: true });
  };

  const resetAuth = () => {
    cleanupErrors();
  };

  return (
    <Box>
      <Button
        style={{ marginBottom: 12 }}
        shape="round"
        type="primary"
        block
        size={isBreakpointEqualsAndBelow('xl') ? 'middle' : 'large'}
        icon={<GoogleOutlined />}
      >
        {t('login.google')}
      </Button>
      <Button
        shape="round"
        type="primary"
        block
        size={isBreakpointEqualsAndBelow('xl') ? 'middle' : 'large'}
        icon={<FacebookOutlined />}
      >
        {t('login.facebook')}
      </Button>
      <Divider plain orientation="center">
        {t('common.or')}
      </Divider>
      <Form name="login" initialValues={{ remember: true }} onFinish={onFinish}>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: t('login.errors.email.required') },
            {
              pattern: emailPattern,
              message: t('login.errors.email.invalid'),
            },
          ]}
        >
          <Input
            style={{ borderRadius: 40 }}
            size={isBreakpointEqualsAndBelow('xl') ? 'middle' : 'large'}
            placeholder={t('login.inputs.email.placeholder')}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: t('login.errors.password.required') },
          ]}
        >
          <Input
            style={{ borderRadius: 40 }}
            size={isBreakpointEqualsAndBelow('xl') ? 'middle' : 'large'}
            type="password"
            placeholder={t('login.inputs.password.placeholder')}
          />
        </Form.Item>
        {authError && (
          <Paragraph
            type="danger"
            style={{ marginBottom: 16, textAlign: 'center' }}
          >
            {authError}
          </Paragraph>
        )}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            shape="round"
            size={isBreakpointEqualsAndBelow('xl') ? 'middle' : 'large'}
            block
          >
            {t('login.elements.mainButtonText')}
          </Button>
        </Form.Item>
        <Form.Item
          name="remember"
          valuePropName="checked"
          style={{ textAlign: 'center' }}
        >
          <Checkbox>{t('login.elements.rememberMe', { days: 30 })}</Checkbox>
        </Form.Item>
        <Form.Item style={{ textAlign: 'center', marginBottom: 0 }}>
          <Trans i18nKey="login.elements.forgotPassword">
            Forgot password?
            <Link className="login-form-forgot" to="/">
              recover it here!
            </Link>
          </Trans>
        </Form.Item>
        <Form.Item style={{ textAlign: 'center', marginBottom: 0 }}>
          <Trans i18nKey="login.elements.register">
            Dont have an account?
            <Link onClick={resetAuth} to="/register">
              register now!
            </Link>
          </Trans>
        </Form.Item>
      </Form>
    </Box>
  );
};

export default LoginForm;
