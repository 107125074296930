import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { Box } from './components';
import { PrivateRoute } from './components/Routing';
import Main from './Main';
import { HomePage, LoginPage, RegisterPage } from './pages';

const GlobalStyles = createGlobalStyle`
  .ant-image-preview-mask {
    background-color: rgba(0, 0, 0, 0.75);
  }
`;

const AppRouter: React.FC = () => {
  return (
    <>
      <GlobalStyles />
      <Box height="100vh">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Main />}>
              <Route path="/" element={<HomePage />} />
              <Route path="/my-account" element={<div>My account</div>} />
              <Route path="/orders" element={<div>orders</div>} />
              <Route path="/addresses" element={<div>addresses</div>} />
              <Route path="/payment-methods" element={<div>payment</div>} />
              <Route path="/cart" element={<div>cart</div>} />
            </Route>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="*" element={<div>404</div>} />
          </Routes>
        </BrowserRouter>
      </Box>
    </>
  );
};

export default AppRouter;
