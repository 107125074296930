export interface SpaceArray {
  [index: number]: number;
  get(index: number): number;
}

const space = [0, 4, 8, 12, 16, 24, 32, 48, 64];

const defaultIndex = 2;

(space as unknown as SpaceArray).get = (index: number) =>
  space[index] ?? space[defaultIndex];

export default space as unknown as SpaceArray;
