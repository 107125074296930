import React from 'react';
import { Layout, Drawer, Image, Avatar, Button } from 'antd';
import { CloseOutlined, EditOutlined, LogoutOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useBreakpoints } from '../../hooks';
import { Box, LangSwitcher, Text } from '..';
import Logo from '../../assets/img/logo.png';
import { theme } from '../../theme';
import colors from '../../theme/colors';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../store/user';
import { AppState } from '../../store';
import { useAuth } from '../../context';
import { useNavigate } from 'react-router-dom';

const { Sider } = Layout;

const MenuButton = styled(Button)`
  border-bottom: 2px solid #ffffff;
  width: 100%;
  text-align: left;
  padding: ${props =>
    `${props.theme.space.get(2)}px ${props.theme.space.get(0)}px`};
  height: auto;
`;

const StyledSider = styled(Sider)`
  background-color: ${props => props.theme.colors.blue[700]};
  padding: ${props => (props.collapsed ? 0 : props.theme.space.get(5))}px;
  .ant-layout-sider-children {
    opacity: ${props => (props.collapsed ? 0 : 1)};
  }
`;

interface SidebarProps {
  isVisible: boolean;
  onClose(): void;
}

const Content: React.FC<Pick<SidebarProps, 'onClose'>> = ({ onClose }) => {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { isBreakpointEqualsAndBelow } = useBreakpoints();
  const loggedUser = useSelector<AppState, AppState['user']['user']>(
    selectCurrentUser,
  );

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  return (
    <>
      <Box py={8} px={4} height="100%" position="relative">
        <Box margin={`0 auto ${theme.space.get(2)}px`} width={['70%', '100%']}>
          <Image preview={false} src={Logo} />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          mb={4}
        >
          <Avatar
            size={isBreakpointEqualsAndBelow('sm') ? 100 : 70}
            src={loggedUser?.photoUrl}
            shape="circle"
          />
          <Button
            style={{ color: 'white' }}
            type="text"
            icon={<EditOutlined />}
          >
            {t('layout.sidebar.editButtonText')}
          </Button>
        </Box>
        <Box>
          <Text fontSize={['h4', 'text']} color="white" mb={0}>
            {t('layout.sidebar.title', { user: loggedUser?.name })}
          </Text>
          <Text fontSize="small" color="white">
            {loggedUser?.email}
          </Text>
          <MenuButton type="text">
            <Text
              style={{ margin: 0 }}
              fontSize={['large', 'normal']}
              color="white"
            >
              {t('layout.sidebar.account')}
            </Text>
          </MenuButton>
          <MenuButton type="text">
            <Text
              style={{ margin: 0 }}
              fontSize={['large', 'normal']}
              color="white"
            >
              {t('layout.sidebar.orders')}
            </Text>
          </MenuButton>
          <MenuButton type="text">
            <Text
              style={{ margin: 0 }}
              fontSize={['large', 'normal']}
              color="white"
            >
              {t('layout.sidebar.addresses')}
            </Text>
          </MenuButton>
          <MenuButton type="text">
            <Text
              style={{ margin: 0 }}
              fontSize={['large', 'normal']}
              color="white"
            >
              {t('layout.sidebar.paymentMethods')}
            </Text>
          </MenuButton>
        </Box>
        <LangSwitcher />
      </Box>
      <Box width="100%" position="absolute" bottom="0" bg="#242424" padding={1}>
        <Button
          icon={<LogoutOutlined />}
          onClick={handleLogout}
          style={{ color: 'white' }}
          type="text"
        >
          {t('layout.sidebar.logout')}
        </Button>
      </Box>
    </>
  );
};

const Parent: React.FC<SidebarProps> = ({ children, isVisible, onClose }) => {
  const { isBreakpointEqualsAndBelow } = useBreakpoints();
  if (isBreakpointEqualsAndBelow('sm')) {
    return (
      <Drawer
        placement="left"
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
        visible={isVisible}
        onClose={onClose}
        drawerStyle={{ backgroundColor: colors.blue[500], padding: 0 }}
        bodyStyle={{ padding: 0 }}
      >
        {children}
      </Drawer>
    );
  }
  return (
    <StyledSider
      breakpoint="md"
      collapsible
      collapsedWidth="0"
      collapsed={!isVisible}
      trigger={null}
    >
      {children}
    </StyledSider>
  );
};

const Sidebar: React.FC<SidebarProps> = props => {
  return (
    <Parent {...props}>
      <Content onClose={props.onClose} />
    </Parent>
  );
};

export default Sidebar;
