import { put, takeLatest } from 'redux-saga/effects';
import { warehouseActions } from '.';
import { productsActions } from '../products';

export function* fetchProducts() {
  yield put(productsActions.fetchProducts());
}

export default function* warehouseSaga() {
  yield takeLatest(warehouseActions.setCurrentWarehouse.type, fetchProducts);
}
