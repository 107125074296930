import { Image } from 'antd';
import React from 'react';
import { Box, Text } from '..';
import { useLang } from '../../context/Lang';
import { Category as CategoryModel } from '../../models';
import { capitalize } from '../../utils';

interface Props {
  category: CategoryModel;
  selected?: boolean;
  onClick?(): void;
}

const Category: React.FC<Props> = ({ category, selected = false, onClick }) => {
  const { lang } = useLang();
  return (
    <Box
      p={2}
      bg={selected ? 'gray' : 'white'}
      height={120}
      flex="0 0 110px"
      display="flex"
      alignItems="center"
      flexDirection="column"
      onClick={onClick}
    >
      <Image
        style={{ width: 80, height: 80 }}
        src={category.img}
        preview={false}
      />
      <Text as="text" textAlign="center" ellipsis>
        {(category as any)[`label${capitalize(lang)}`]}
      </Text>
    </Box>
  );
};

export default Category;
