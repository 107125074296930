import { Button, Input, Modal } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Text } from '..';
import { Address } from '../../models';
import { WarehouseService } from '../../services';
import { useDispatch } from '../../store';
import {
  selectAddresses,
  selectDesiredAddress,
  userActions,
} from '../../store/user';
import { warehouseActions } from '../../store/warehouse';
import { getAddress } from '../../utils';
import { wrapper } from '../../utils/functions';

interface Props {
  onClose(): void;
  isVisible: boolean;
}

const AddressesModal: React.FC<Props> = ({ onClose, isVisible }) => {
  const { t } = useTranslation();
  const addresses = useSelector(selectAddresses);
  const selectedAddress = useSelector(selectDesiredAddress);
  const dispatch = useDispatch();
  const [customZipCode, setCustomZipCode] = useState<string>('');

  const selectAddress = (address: Address) => {
    dispatch(userActions.setDesiredAddress(address));
  };

  const handleZipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomZipCode(event.target.value);
  };

  const handleCustomZipAction = async () => {
    const response = await WarehouseService.getByZipCode(customZipCode);
    dispatch(warehouseActions.setCurrentWarehouse(response[0]));
  };

  return (
    <Modal onCancel={onClose} footer={null} visible={isVisible} closable>
      <Text
        textAlign="center"
        color="blue.500"
        fontWeight="bold"
        fontSize="large"
        width="100%"
        my={4}
      >
        {t('layout.addressModal.title')}
      </Text>
      <Text fontSize="small">{t('layout.addressModal.disclaimer')}</Text>
      {addresses &&
        addresses.length &&
        addresses.map(address => {
          return (
            <Box
              p="2"
              borderColor="gray"
              borderWidth={1}
              borderStyle="solid"
              bg={selectedAddress?.id === address.id ? 'gray' : 'white'}
              mb="2"
              key={`address-${address.id}-${address.street}`}
              onClick={wrapper(selectAddress, address)}
            >
              <Text mb="1">{getAddress(address)}</Text>
            </Box>
          );
        })}
      <Text>{t('layout.addressModal.zipCodeLabel')}</Text>
      <Box width="100%">
        <Input
          value={customZipCode}
          style={{ width: '60%' }}
          onChange={handleZipChange}
        />
        <Button
          onClick={handleCustomZipAction}
          type="primary"
          style={{ width: '40%' }}
        >
          {t('actions.confirm')}
        </Button>
      </Box>
    </Modal>
  );
};

export default AddressesModal;
