import React from 'react';
import { Select } from 'antd';
import { useLang } from '../../context/Lang';
import { Box } from '..';
import { useTheme } from 'styled-components';

const { Option } = Select;

const LangSwitcher: React.FC = () => {
  const { availableLangs, lang, changeLang } = useLang();
  const theme = useTheme();

  return (
    <Box position="absolute" bottom={7} right={2}>
      <Select
        defaultValue={lang}
        onChange={changeLang}
        bordered={false}
        style={{ color: theme.colors.white }}
        showArrow={false}
      >
        {availableLangs?.map(lg => {
          return (
            <Option key={lg.name} value={lg.key}>
              {lg.icon} {lg.name}
            </Option>
          );
        })}
      </Select>
    </Box>
  );
};

export default LangSwitcher;
