const colors = {
  blue: {
    500: '#116ce3',
    700: '#0e2ead',
    900: '#001978',
  },
  black: '#242424',
  white: '#FFFFFF',
  primary: '#001978',
  gray: '#ebf2fc',
};

export default colors;
