import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '..';
import { getAddress } from '../../utils';

const userSlice = (state: AppState) => state.user;

export const selectAddresses = createSelector(
  userSlice,
  state => state.addresses,
);

export const selectDesiredAddress = createSelector(
  userSlice,
  state => state.desiredAddres,
);

export const selectReadableAddress = createSelector(userSlice, state =>
  state.desiredAddres ? getAddress(state.desiredAddres) : '',
);

export const selectCurrentUser = createSelector(userSlice, state => state.user);

export const selectAuthToken = createSelector(userSlice, state => state.token);
