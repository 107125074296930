import { Input, Select } from 'antd';
import debounce from 'lodash.debounce';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box } from '..';
import { selectCategories } from '../../store/categories';
import { useLang } from '../../context/Lang';
import { capitalize } from '../../utils';
import { useDispatch } from '../../store';
import { searchActions, selectSearchCategory } from '../../store/search';

const { Option } = Select;
const { Search } = Input;

const SearchBar: React.FC = () => {
  const { t } = useTranslation();
  const { lang } = useLang();
  const categories = useSelector(selectCategories);
  const searchCategory = useSelector(selectSearchCategory);
  const dispatch = useDispatch();

  const handleCategoryChange = (id: number) =>
    dispatch(searchActions.setSearchCategory(id));

  const updateSearchInput = debounce(
    (val: string) => dispatch(searchActions.setSearch(val)),
    500,
  );

  const handleSearchInput = (value: string) => {
    updateSearchInput(value);
  };

  return (
    <Box
      minHeight={50}
      maxWidth={800}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Search
        placeholder={t('layout.searchbar.placeholder')}
        size="middle"
        onSearch={handleSearchInput}
        onChange={event => handleSearchInput(event.target.value)}
        allowClear
        addonBefore={
          <Select
            placeholder={t('layout.searchbar.dropDownPlaceholder')}
            style={{ width: 130 }}
            value={searchCategory}
            onChange={handleCategoryChange}
            allowClear
          >
            {categories.map(category => (
              <Option
                key={`category-${category.id}-${category.labelEn}`}
                value={category.id}
              >
                {(category as any)[`label${capitalize(lang)}`]}
              </Option>
            ))}
          </Select>
        }
        style={{ flex: 1 }}
      />
    </Box>
  );
};

export default SearchBar;
