import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Box,
  Text,
  CategoriesCarousel,
  ProductsList,
  FilterDrawer,
  FilterHandler,
} from '../../components';
import { useDispatch } from '../../store';
import { Category } from '../../models';
import {
  categoriesActions,
  selectCategories,
  selectCurrentCategory,
} from '../../store/categories';
import {
  selectHighlightedProducts,
  selectProducts,
} from '../../store/products';
import { selectSearchQuery } from '../../store/search';
import { Divider } from 'antd';
import { useDialogHandler } from '../../hooks';

const Home: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filterDrawer = useDialogHandler();
  const sortDrawer = useDialogHandler();
  const setSelectedCategory = (category: Category) =>
    dispatch(categoriesActions.setCurrentCategory(category));
  const categories = useSelector(selectCategories);
  const selectedCategory = useSelector(selectCurrentCategory);
  const products = useSelector(selectProducts);
  const highlightedProducts = useSelector(selectHighlightedProducts);
  const search = useSelector(selectSearchQuery);

  return (
    <Box>
      {!search && (
        <>
          <Text
            textAlign="center"
            color="blue.500"
            fontWeight="bold"
            fontSize="large"
            width="100%"
            my={4}
          >
            {t('home.categories.highlight')}
          </Text>
          <CategoriesCarousel
            onClick={setSelectedCategory}
            data={categories}
            selected={selectedCategory}
          />
          {highlightedProducts && highlightedProducts.length > 0 && (
            <>
              <ProductsList
                title={t('home.products.highlight')}
                orientation="horizontal"
                data={highlightedProducts}
              />
            </>
          )}
        </>
      )}
      <Divider orientation="right">
        <FilterHandler onFilter={filterDrawer.open} onSort={sortDrawer.open} />
      </Divider>
      <ProductsList data={products} />
      <FilterDrawer
        isVisible={filterDrawer.isVisible}
        onClose={filterDrawer.close}
      />
    </Box>
  );
};

export default Home;
