import React from 'react';
import { Box, Product, Text } from '..';
import { Product as ProductModel } from '../../models';

interface Props {
  data: ProductModel[];
  orientation?: 'horizontal' | 'vertical';
  title?: string;
}

const ProductsList: React.FC<Props> = ({
  data,
  orientation = 'vertical',
  title,
}) => {
  return (
    <>
      {title && data.length > 0 && (
        <Text
          textAlign="center"
          color="blue.500"
          fontWeight="bold"
          fontSize="large"
          width="100%"
          my={4}
        >
          {title}
        </Text>
      )}
      <Box
        width="100%"
        flexWrap={orientation === 'vertical' ? 'wrap' : 'nowrap'}
        display="flex"
        flexDirection="row"
        overflowX={orientation === 'horizontal' ? 'auto' : 'initial'}
      >
        {data &&
          data.map(product => {
            return (
              <Box
                key={`product-${product.id}-${product.config.warehouseId}`}
                width={[1 / 2, 1 / 3, 1 / 4, 1 / 5]}
                flex={orientation === 'horizontal' ? '0 0 40%' : ''}
              >
                <Product
                  product={product}
                  hidePrice={orientation === 'horizontal'}
                />
              </Box>
            );
          })}
      </Box>
    </>
  );
};

export default ProductsList;
