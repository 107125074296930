import client from '../config/client';
import { Banner } from '../models';
import Service from './service';

export default class BannerService implements Service {
  private static instance = client;

  public static async getByLocation(locationCode: string): Promise<Banner> {
    return await this.instance
      .service('banner_location')
      .find({ query: { locationCode } });
  }
}
