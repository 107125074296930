import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeLatest } from 'redux-saga/effects';
import { categoriesActions } from '.';
import { Category } from '../../models';
import { productsActions } from '../products';

const { setCurrentCategory } = categoriesActions;

function* onCurrentCategoryChange(action: PayloadAction<Category>) {
  const currentCategory = action.payload;
  yield put(
    productsActions.fetchProducts({
      category: currentCategory.id,
    }),
  );
}

export function* categoriesSaga() {
  yield takeLatest(setCurrentCategory.type, onCurrentCategoryChange);
}
