import React, { useEffect, useState } from 'react';
import { Layout as LayoutBase } from 'antd';
import Header from './Header';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';
import { useBreakpoints } from '../../hooks';
import Sidebar from './Sidebar';
import { AddressesModal, Box } from '..';
import { theme } from '../../theme';

const { Content } = LayoutBase;

const Layout: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState<boolean>(false);
  const { isBreakpointEqualsAndBelow, currentBreakpoint } = useBreakpoints();

  const toggleSider = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleAddressModal = () => {
    setIsAddressModalOpen(!isAddressModalOpen);
  };

  useEffect(() => {
    if (isBreakpointEqualsAndBelow('md')) {
      setIsSidebarOpen(false);
      return;
    }
    setIsSidebarOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBreakpoint]);

  return (
    <>
      <LayoutBase
        style={{
          height: '100%',
          minHeight: '100%',
        }}
      >
        <Sidebar isVisible={isSidebarOpen} onClose={toggleSider} />
        <LayoutBase
          style={{ minHeight: '100%', backgroundColor: theme.colors.white }}
        >
          <Header
            onMenuClick={toggleSider}
            onAddressClick={toggleAddressModal}
          />
          <Content
            style={{
              overflowY: 'auto',
            }}
          >
            <Box
              width="100%"
              maxWidth={1336}
              m="0 auto"
              minHeight="calc(100vh - 96px - 96px)"
            >
              <Outlet />
            </Box>
            <Footer />
          </Content>
        </LayoutBase>
      </LayoutBase>
      <AddressesModal
        onClose={toggleAddressModal}
        isVisible={isAddressModalOpen}
      />
    </>
  );
};

export default Layout;
