import { all, call, spawn } from 'redux-saga/effects';
import { categoriesSaga } from './categories/categories.saga';
import productsSaga from './products/products.saga';
import searchSaga from './search/search.saga';
import userSaga from './user/user.saga';
import warehouseSaga from './warehouse/warehouse.saga';

export default function* rootSaga() {
  const sagas = [
    categoriesSaga,
    productsSaga,
    searchSaga,
    userSaga,
    warehouseSaga,
  ];
  yield all(
    sagas.map(saga =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (err) {
            console.log(err);
          }
        }
      }),
    ),
  );
}
