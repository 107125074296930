import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Category } from '../../models';
import { CategoriesState } from './categories.types';

const initialState: CategoriesState = {
  categories: [],
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategories(state, action: PayloadAction<Category[]>) {
      state.categories = action.payload;
    },
    setCurrentCategory(state, action: PayloadAction<Category>) {
      state.currentCategory = action.payload;
    },
  },
});

export const { actions: categoriesActions, reducer: categoriesReducer } =
  categoriesSlice;
