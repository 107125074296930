import styled from 'styled-components';
import {
  layout,
  flexbox,
  space,
  color,
  shadow,
  compose,
  LayoutProps,
  FlexProps,
  SpaceProps,
  ColorProps,
  ShadowProps,
  system,
} from 'styled-system';
import { Card as BaseCard } from 'antd';
import React from 'react';

type ElevationProps = {
  elevation?: number;
};

export type CardProps = LayoutProps &
  FlexProps &
  SpaceProps &
  ColorProps &
  ShadowProps &
  ElevationProps;

const elevation = system({
  elevation: {
    scale: 'shadows',
    property: 'boxShadow',
  },
});

const capabilities = compose(layout, flexbox, space, color, shadow, elevation);

const Card = styled<React.ComponentType<CardProps>>(BaseCard as any)`
  ${capabilities}
`;

export default Card;
