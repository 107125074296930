import { Image } from 'antd';
import React from 'react';
import { Box, Text } from '..';
import { capitalize, currency } from '../../utils';
import { Product as ProductModel } from '../../models';
import { useLang } from '../../context/Lang';

interface ProductProps {
  product: ProductModel;
  hidePrice?: boolean;
}

const Product: React.FC<ProductProps> = ({ product, hidePrice = false }) => {
  const { lang } = useLang();
  return (
    <Box width="100%" p={2}>
      <Box
        height={100}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Image preview={false} height="100%" src={product.imgs[0]} />
      </Box>
      <Text ellipsis={{ rows: 2 }} textAlign="center" mb={1}>
        {(product as any)[`name${capitalize(lang)}`]}
      </Text>
      {!hidePrice && (
        <Text textAlign="center" mb={2}>
          {currency(product.config.price)}
        </Text>
      )}
    </Box>
  );
};

export default Product;
