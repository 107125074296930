import feathers from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';
import authentication, {
  AuthenticationClientOptions,
} from '@feathersjs/authentication-client';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_API_URL || 'http://localhost:3000';

const client = feathers();
const restClient = rest(baseUrl);

export const authenticationConfig: Partial<AuthenticationClientOptions> = {
  storageKey: 'zellship-auth-token',
};

client.configure(restClient.axios(axios));
client.configure(authentication(authenticationConfig));

export default client;
