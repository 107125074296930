import { AuthenticationRequest } from '@feathersjs/authentication/lib';
import React, { useEffect, useState } from 'react';
import { AuthenticationService } from '../../services';
import { useDispatch } from '../../store';
import { userActions } from '../../store/user';

interface AuthContext {
  authenticated: boolean;
  login(request: AuthenticationRequest): Promise<void>;
  logout(): Promise<void>;
  register(request: any): Promise<void>;
  cleanupErrors(): void;
  loading: boolean;
  authError?: string;
}

const authContext = React.createContext<AuthContext>({} as AuthContext);

function useAuth(): AuthContext {
  const [authenticated, setAuthenticated] = useState(
    AuthenticationService.isAuthenticated,
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [authError, setAuthError] = useState<string | undefined>(undefined);
  const dispatch = useDispatch();

  const checkAuth = async () => {
    setLoading(true);

    try {
      const result = await AuthenticationService.reAuthenticate();
      if (result.authentication) {
        setAuthenticated(true);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setAuthenticated(false);
    }
  };

  const login: AuthContext['login'] = async request => {
    setLoading(true);
    try {
      const response = await AuthenticationService.authenticate(request);
      setAuthenticated(true);
      setAuthError(undefined);
      dispatch(userActions.setUser(response.user));
      dispatch(userActions.setAuthenticationToken(response.accessToken));
    } catch (error) {
      console.error('Unable to authenticate user', error);
      setAuthenticated(false);
      setAuthError(
        'Invalid credentials. Try againg with different email or password.',
      );
    } finally {
      setLoading(false);
    }
  };

  const logout: AuthContext['logout'] = async () => {
    setLoading(true);
    try {
      await AuthenticationService.logout();
    } catch (error) {
      console.error('Something failed during log out process');
      setAuthError('Something failed during logout');
    } finally {
      setAuthenticated(false);
      setLoading(false);
    }
  };

  const register: AuthContext['register'] = async params => {
    setLoading(true);
    try {
      await AuthenticationService.register(params);
      await AuthenticationService.authenticate(params);
      setAuthenticated(true);
      setAuthError(undefined);
    } catch (error) {
      console.error('Something failed during log out process');
      setAuthError('Invalid data to register');
      setAuthenticated(false);
      setLoading(false);
    }
  };

  const cleanupErrors: AuthContext['cleanupErrors'] = () => {
    setAuthError(undefined);
  };

  useEffect(() => {
    checkAuth();
  }, []);

  return {
    authenticated,
    authError,
    loading,
    login,
    logout,
    register,
    cleanupErrors,
  };
}

export const AuthProvider: React.FC = ({ children }) => {
  const auth = useAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export default function AuthConsumer() {
  return React.useContext(authContext);
}
